import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "OptionListItem",
  components: {
    ThemisDecision
  },
  data: () => ({
    highlightOptionListItem : false,
    onDraggingOptionListItem: false
  }),
  props: {
    pOptionListItem              : Object,
    pLevel                       : Number,
    pOptionListItemIndex         : Number,
    pIsUpdatingParentId          : Boolean,
    pIsOptionListItemDraggable   : Boolean,
    pIsUpdatingSortingOrder      : Boolean,
    pShowOptionListItemActionIcon: Boolean,
    pIsSortingOrderUpdated       : Boolean,
    pDragEnteredOptionListItem   : Object,
    pDraggedOptionListItem       : Object,
    pIsRemovingOptionListItem    : Boolean,
    pIsOptionListSystemDefault   : Boolean
  },
  computed: {
    isParent() {
      return this.pOptionListItem.children.length
    },
    isIndentDisabled() {
      if (this.pLevel >= 3 || this.hasChildrenAtLevelThree(this.pOptionListItem, this.pLevel + 1)
        || this.pOptionListItemIndex === 0 || this.pIsUpdatingParentId
        || this.pIsRemovingOptionListItem || this.pIsUpdatingSortingOrder) {
        return true
      }
      return false
    },
    isOutdentDisabled() {
      if (this.pLevel === 0 || this.pIsUpdatingParentId
        || this.pIsRemovingOptionListItem || this.pIsUpdatingSortingOrder) {
        return true
      }
      return false
    },
    isRemovingOptionListItemDisabled() {
      if (this.pIsUpdatingParentId || this.pIsRemovingOptionListItem
        || this.pIsUpdatingSortingOrder) {
        return true
      }
      return false
    }
  },
  methods: {
    hasChildrenAtLevelThree(optionListItem, childLevel) {
      for (const child of optionListItem.children) {
        if (childLevel === 3 || this.hasChildrenAtLevelThree(child, childLevel + 1)) {
          return true
        }
      }
      return false
    },
    indentOptionListItem(optionListItem) {
      this.$emit("indentOptionListItem", optionListItem)
    },

    outdentOptionListItem(optionListItem) {
      this.$emit("outdentOptionListItem", optionListItem)
    },

    onOptionListItemDragStart(event, optionListItem) {
      this.onDraggingOptionListItem = true
      this.$emit("onOptionListItemDragStart", optionListItem)
      event.stopPropagation()
    },

    onOptionListItemDragEnter(event, optionListItem) {
      this.$emit("onOptionListItemDragEnter", optionListItem)
      event.stopPropagation()
    },

    onOptionListItemDragOver(event) {
      this.$emit("onOptionListItemDragOver", event)
    },

    onOptionListItemDragEnd(event) {
      this.onDraggingOptionListItem = false
      this.$emit("onOptionListItemDragEnd")
      event.stopPropagation()
    },

    onNestedOptionListItemDragStart(optionListItem) {
      this.$emit("onOptionListItemDragStart", optionListItem)
    },

    onNestedOptionListItemDragEnter(optionListItem) {
      this.$emit("onOptionListItemDragEnter", optionListItem)
    },

    onNestedOptionListItemDragOver(event) {
      this.$emit("onOptionListItemDragOver", event)
    },

    onNestedOptionListItemDragEnd() {
      this.$emit("onOptionListItemDragEnd")
    },

    optionListItemIsDraggable(value) {
      this.$emit("optionListItemIsDraggable", value)
    },

    classForOptionListItem(item) {
      let classList = ""
      if (item.id === this.pDragEnteredOptionListItem?.id && item.id !== this.pDraggedOptionListItem?.id) {
        if (this.pDragEnteredOptionListItem.sortingOrder < this.pDraggedOptionListItem.sortingOrder) {
          classList += "drop-row-down"
        } else {
          classList += "drop-row-up"
        }
      } else if (this.highlightOptionListItem) {
        classList += "grey lighten-4"
      } else {
        classList += "white"
      }
      return classList
    },

    deleteOptionListItem(optionListItem) {
      this.$emit("deleteOptionListItem", optionListItem)
    }
  },
  watch: {
    pIsSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.highlightOptionListItem = false
        }
      }
    }

  }
}