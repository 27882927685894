import { mapActions, mapGetters, mapMutations } from "vuex"
import { VALIDATION_ERROR, MAX_CHARACTER_LIMIT } from "@/constants"
import ThemisInput from "@/components/shared/input"
import ThemisOptionListItem from "@/components/option-list/option-list-item"
import { OPTION_LIST } from "@/constants/bread-crumbs/option-list"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"

export default {
  name      : "OptionList",
  components: {
    ThemisInput,
    ThemisOptionListItem
  },
  data: () => ({
    localOptionList                 : undefined,
    isOptionListNameInputFocussed   : false,
    showOptionListInput             : false,
    name                            : undefined,
    submitEventType                 : undefined,
    isUpdatedNameDuplicated         : false,
    deletedOptionListItem           : undefined,
    optionListItemIsDraggable       : false,
    draggedOptionListItem           : undefined,
    dragEnteredOptionListItem       : undefined,
    containsChildren                : undefined,
    optionListNameCharacterLimit    : MAX_CHARACTER_LIMIT.OPTION_LIST_NAME,
    optionListItemNameCharacterLimit: MAX_CHARACTER_LIMIT.OPTION_LIST_ITEM_NAME
  }),
  computed: {
    ...mapGetters({
      optionLists                     : "optionLists/optionLists",
      optionListItems                 : "optionListItems/optionListItems",
      isOptionListItemRemoved         : "optionListItems/isOptionListItemRemoved",
      isAddingOptionListItem          : "optionListItems/isAddingOptionListItem",
      isOptionListItemAdded           : "optionListItems/isOptionListItemAdded",
      isBulkOptionListItemsAdded      : "optionListItems/isBulkOptionListItemsAdded",
      isAddingBulkOptionListItems     : "optionListItems/isAddingBulkOptionListItems",
      numberOfOptionListItemsAdded    : "optionListItems/numberOfOptionListItemsAdded",
      numberOfDuplicateOptionListItems: "optionListItems/numberOfDuplicateOptionListItems",
      optionListItemAddError          : "optionListItems/optionListItemAddError",
      isUpdatingOptionListName        : "optionLists/isUpdatingOptionListName",
      isOptionListNameUpdated         : "optionLists/isOptionListNameUpdated",
      optionListNameUpdateError       : "optionLists/optionListNameUpdateError",
      isUpdatingSortingOrder          : "optionListItems/isUpdatingSortingOrder",
      isSortingOrderUpdated           : "optionListItems/isSortingOrderUpdated",
      isUpdatingParentId              : "optionListItems/isUpdatingParentId",
      isRemovingOptionListItem        : "optionListItems/isRemovingOptionListItem"
    }),
    optionList() {
      return this.optionLists.find(optionList => optionList.id === +this.$route.params.id)
    },
    filteredOptionListItems() {
      return this.optionListItems
        .filter(optionListItem => optionListItem.optionListId === +this.$route.params.id)
        .sort((firstOptionListItem, secondOptionListItem) =>
          firstOptionListItem.sortingOrder - secondOptionListItem.sortingOrder)
    },
    optionListItemsForDisplay() {
      return this.getNestedOptionListItems(this.filteredOptionListItems)
    },
    isOptionListItemDuplicate() {
      if (this.optionListItemAddError) {
        return this.optionListItemAddError.field === VALIDATION_ERROR.FIELD.NAME_OPTION_LIST_ID &&
          this.optionListItemAddError.type === VALIDATION_ERROR.TYPE.DUPLICATE
      }
    },
    showOptionListItemActionIcon() {
      return !this.optionList.systemDefault && this.filteredOptionListItems.length > 1
    },
    isOptionListItemDraggable() {
      return this.showOptionListItemActionIcon && this.optionListItemIsDraggable
        && !this.isUpdatingSortingOrder && !this.isUpdatingParentId && !this.isRemovingOptionListItem
    },
    isOptionListNameDuplicate() {
      return this.optionListNameUpdateError?.type === "duplicate"
    },
    isOptionListNameChanged() {
      return this.optionList?.name !== this.localOptionList?.name
    },
    showAddOptionListItemButton() {
      return !this.showOptionListInput && !this.optionList.systemDefault
    },
    classForAddOptionListItem() {
      return this.optionListItemsForDisplay.length ? "grey lighten-5" : "white"
    },
    isAddingOptionListItems() {
      return this.isAddingOptionListItem || this.isAddingBulkOptionListItems
    },
    isNameValid() {
      return this.name &&
        this.name.trim().length &&
        this.name.length <= this.optionListItemNameCharacterLimit
    },
    isOptionListSystemDefault() {
      return this.optionList.systemDefault
    }
  },
  methods: {
    ...mapActions({
      addOptionListItem     : "optionListItems/addOptionListItem",
      bulkAddOptionListItems: "optionListItems/bulkAddOptionListItems",
      updateOptionListItem  : "optionListItems/updateOptionListItem",
      updateOptionList      : "optionLists/updateOptionList",
      removeOptionListItem  : "optionListItems/removeOptionListItem",
      notify                : "shared/notify"
    }),
    ...mapMutations({
      setOptionListItemAddError : "optionListItems/setOptionListItemAddError",
      resetOptionListUpdateError: "optionLists/resetOptionListUpdateError",
      setBreadcrumbs            : "shared/setBreadcrumbs",
      setPageTitle              : "shared/setPageTitle"
    }),
    handleAddOptionListSubmitEvent() {
      this.addOptionListItem({
        name        : this.name.trim(),
        optionListId: +this.$route.params.id
      })
    },
    handleCancelOptionListItemAdd() {
      this.name                = undefined
      this.showOptionListInput = false
    },
    handleEnterKeyDownEvent() {
      this.submitEventType = "enter"
      if (this.isNameValid) {
        this.handleAddOptionListSubmitEvent()
      }
    },
    deleteOptionListItem(optionListItem) {
      this.deletedOptionListItem = optionListItem
      this.containsChildren      = !!optionListItem.children.length
      this.removeOptionListItem(optionListItem.id)
    },
    onOptionListItemDragStart(optionListItem) {
      this.draggedOptionListItem     = optionListItem
      this.dragEnteredOptionListItem = this.draggedOptionListItem
    },
    onOptionListItemDragEnter(optionListItem) {
      this.dragEnteredOptionListItem = optionListItem
    },
    onOptionListItemDragOver(event) {
      event.preventDefault()
    },
    findOptionListItemLevelAndIsParentById(id, optionListItems, level) {
      for (const optionListItem of optionListItems) {
        if (optionListItem.id === id) {
          return { level, isParent: !!optionListItem.children.length }
        }
        if (optionListItem.children.length) {
          const foundResult = this.findOptionListItemLevelAndIsParentById(
            id, optionListItem.children, level + 1
          )
          if (foundResult.level !== -1) {
            return foundResult
          }
        }
      }
      return { level: -1, isParent: false }
    },

    getChildrenLevel(optionListItems) {
      if (!optionListItems.children || optionListItems.children.length === 0) {
        return { level: 0, numChildren: 0 }
      }

      let maxChildLevel = 0
      let totalChildren = 0

      for (const child of optionListItems.children) {
        const { level, numChildren } = this.getChildrenLevel(child)
        maxChildLevel                = Math.max(maxChildLevel, level)
        totalChildren               += 1 + numChildren // Add 1 for the current child and its descendants
      }

      return { level: maxChildLevel + 1, numChildren: totalChildren }
    },

    getIsItemInChildHierarchy(itemId, draggedOptionListItem) {

      if (itemId === draggedOptionListItem?.parentId) {
        return true
      } else {
        if (draggedOptionListItem?.parentId) {
          const parentOptionListItem = this.optionListItemsForDisplay.find(
            optionListItem => optionListItem.id === draggedOptionListItem.parentId
          )
          return this.getIsItemInChildHierarchy(itemId, parentOptionListItem)
        } else {
          return false
        }
      }
    },

    onOptionListItemDragEnd() {
      if (this.draggedOptionListItem.id !== this.dragEnteredOptionListItem.id) {
        const isOptionListItemDragged = this.draggedOptionListItem.sortingOrder
        !== this.dragEnteredOptionListItem.sortingOrder

        let sortingOrder = isOptionListItemDragged ?
          this.dragEnteredOptionListItem.sortingOrder : this.dragEnteredOptionListItem.sortingOrder - 1

        const isOptionListItemDraggedDown = this.draggedOptionListItem.sortingOrder
        <= this.dragEnteredOptionListItem.sortingOrder

        const previousOptionListItemId = this.filteredOptionListItems.find(optionListItem =>
          optionListItem.sortingOrder === (isOptionListItemDraggedDown ? sortingOrder : sortingOrder - 1))?.id

        let lastChildLevel = this.getChildrenLevel(this.draggedOptionListItem).level

        if (previousOptionListItemId) {
          const previousOptionListItemLevelAndIsParent = this.findOptionListItemLevelAndIsParentById(
            previousOptionListItemId, this.optionListItemsForDisplay, 0
          )

          lastChildLevel += previousOptionListItemLevelAndIsParent.isParent
            ? previousOptionListItemLevelAndIsParent.level + 1
            : previousOptionListItemLevelAndIsParent.level
        }

        if (isOptionListItemDraggedDown) {
          sortingOrder -= this.getChildrenLevel(this.draggedOptionListItem).numChildren
        }

        const isItemInChildHierarchy = this.getIsItemInChildHierarchy(
          this.draggedOptionListItem.id, this.dragEnteredOptionListItem
        )

        if (lastChildLevel < 4 && !isItemInChildHierarchy) {
          this.updateOptionListItem({
            optionListId    : this.optionList.id,
            optionListItemId: this.draggedOptionListItem.id,
            sortingOrder
          })
        } else {
          this.draggedOptionListItem     = undefined
          this.dragEnteredOptionListItem = undefined
        }
      }
    },
    handlePasteEvent(event) {
      const items      = event.clipboardData.getData("Text")
      const itemsToAdd = items.split("\n")
        .map(item => item.trim())
        .filter(item => item.length && item.length <= this.optionListItemNameCharacterLimit)
      if (itemsToAdd.length) {
        this.bulkAddOptionListItems({
          optionListItems: itemsToAdd,
          optionListId   : +this.$route.params.id
        })
      }
    },
    handleOptionListNameInputOnBlurEvent(onBlur) {
      onBlur()
      this.verifyAndUpdateOptionListName()
    },
    handleOptionListNameInputOnEnter() {
      this.$refs.input_option_list_name.blur()
    },
    verifyAndUpdateOptionListName() {
      if (this.isOptionListNameChanged) {
        if (this.localOptionList.name &&
          this.localOptionList.name.length <= this.optionListNameCharacterLimit) {
          this.updateOptionList({
            id  : this.localOptionList.id,
            name: this.localOptionList.name
          })
        }
      }
    },
    getNestedOptionListItems(optionListItems) {
      const optionListItemsMapWithChildren = {}
      const nestedOptionListItems          = []

      optionListItems.forEach(optionListItem => {
        optionListItemsMapWithChildren[optionListItem.id] = { ...optionListItem, children: [] }
      })

      optionListItems.forEach(optionListItem => {
        const parentOptionListItem = optionListItemsMapWithChildren[optionListItem.parentId]
        if (parentOptionListItem) {
          parentOptionListItem.children.push(optionListItemsMapWithChildren[optionListItem.id])
        } else {
          nestedOptionListItems.push(optionListItemsMapWithChildren[optionListItem.id])
        }
      })
      return nestedOptionListItems
    },

    getPreviousSiblingId(currentId, optionListItems) {
      for (const optionListItem of optionListItems) {
        if (optionListItem.children.length) {
          const previousSiblingId = this.getPreviousSiblingId(currentId, optionListItem.children)
          if (previousSiblingId) {
            return previousSiblingId
          }
        }
        if (optionListItem.id === currentId) {
          const currentIndex = optionListItems.findIndex(optionListItem => optionListItem.id === currentId)
          if (currentIndex > 0) {
            return optionListItems[currentIndex - 1].id
          }
        }
      }
    },
    indentOptionListItem(optionListItem) {
      const parentId = this.getPreviousSiblingId(optionListItem.id, this.optionListItemsForDisplay)
      if (parentId) {
        this.updateOptionListItem({
          optionListId    : this.optionList.id,
          optionListItemId: optionListItem.id,
          parentId
        })
      }
    },

    findGrandParentId(optionListItems, currentId, parentId = null, grandParentId = null) {
      for (const optionListItem of optionListItems) {
        if (optionListItem.id === currentId) {
          return grandParentId
        }
        if (optionListItem.children.length) {
          const result = this.findGrandParentId(optionListItem.children, currentId, optionListItem.id, parentId)
          if (result) {
            return result
          }
        }
      }
    },
    outdentOptionListItem(optionListItem) {
      const parentId = this.findGrandParentId(this.optionListItemsForDisplay, optionListItem.id)
      this.updateOptionListItem({
        optionListId    : this.optionList.id,
        optionListItemId: optionListItem.id,
        parentId
      })
    },

    optionListItemIsDraggableValue(value) {
      this.optionListItemIsDraggable = value
    }
  },
  watch: {
    optionList: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localOptionList) {
          this.localOptionList = { ...newValue }
        }
      }
    },
    "localOptionList.name": {
      immediate: true,
      handler  : function() {
        if (this.optionListNameUpdateError) {
          this.resetOptionListUpdateError(["name"])
        }
      }
    },
    isOptionListItemAdded: {
      handler: async function(newValue) {
        if (newValue) {
          this.name = undefined
          if (this.submitEventType === "enter") {
            this.showOptionListInput = true
            this.submitEventType     = undefined
            await this.$nextTick()
            this.$refs.input_option_list_item_name.focus()
          } else {
            this.showOptionListInput = false
          }
        }
      }
    },
    isBulkOptionListItemsAdded: {
      handler: async function(newValue) {
        if (newValue) {
          this.name                = undefined
          this.showOptionListInput = false

          if (!this.numberOfDuplicateOptionListItems) {
            this.notify({
              type      : "success",
              text      : "1028",
              parameters: {
                numberOfOptionListItemsAdded: this.numberOfOptionListItemsAdded
              }
            })
          } else if (this.numberOfDuplicateOptionListItems && this.numberOfOptionListItemsAdded) {
            this.notify({
              type      : "warning",
              text      : "1029",
              parameters: {
                numberOfOptionListItemsAdded    : this.numberOfOptionListItemsAdded,
                numberOfDuplicateOptionListItems: this.numberOfDuplicateOptionListItems
              }
            })
          } else if (!this.numberOfOptionListItemsAdded) {
            this.notify({
              type      : "warning",
              text      : "1030",
              parameters: {
                numberOfDuplicateOptionListItems: this.numberOfDuplicateOptionListItems
              }
            })
          }
        }
      }
    },
    name: {
      handler: function() {
        this.setOptionListItemAddError(undefined)
      }
    },
    isOptionListNameUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.setBreadcrumbs(
            OPTION_LIST({ params: { id: this.localOptionList.id } }, this.localOptionList.name
            )
          )
          this.setPageTitle(PAGE_TITLE_WITHOUT_TRANSLATION(this.localOptionList.name))
        }
      }
    },
    isOptionListItemRemoved: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          if (this.containsChildren) {
            this.notify({
              type      : "success",
              text      : "1258",
              parameters: {
                optionListItemName: this.deletedOptionListItem.name
              }
            })
          } else {
            this.notify({
              type      : "success",
              text      : "1026",
              parameters: {
                optionListItemName: this.deletedOptionListItem.name
              }
            })
          }
          this.deletedOptionListItem = undefined
        }
      }
    },
    isSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.draggedOptionListItem     = undefined
          this.dragEnteredOptionListItem = undefined
        }
      }
    }
  }
}